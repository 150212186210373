import { ReactNode } from "react";

export enum DesktopLinkPositions {
  LEFT,
  RIGHT,
  NONE,
}

export interface NavigationLink {
  id: string;
  title: string;
  desktopPosition: DesktopLinkPositions;
  Link: ({
    children,
    className,
  }: {
    children: ReactNode;
    className?: string;
  }) => JSX.Element;
  children?: {
    title: string;
    url: string;
    icon: ReactNode;
    tag?: string;
    isExternal: boolean;
    isActive?: boolean;
  }[];
  hide?: boolean;
}
