import { useIsAuthenticating } from "@core/hooks/useIsAuthenticating";
import { useSession } from "@core/hooks/useSession";
import useUser, { User } from "@core/hooks/useUser";
import Avatar from "@core/ui/Avatar";
import { DeviceTypes } from "@features/auth/types";
import { TopicButtons } from "@features/community-v2/components/TopicSidebar";
import AccountSwitchModal from "@features/linked-accounts/components/AccountSwitchModal";
import { useTherapyHomeData } from "@features/therapy/hooks/useTherapyHomeData";
import { PreviewNotificationsMenu } from "@features/user-notifications";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import { throttle } from "lodash";
import { useRouter } from "next/router";
import { signOut } from "next-auth/react";
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueryClient } from "react-query";

import DesktopMenu, { NextLinkWithForwardedProps } from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { NavigationLink } from "./types";

interface GetNavigationLinksArgs {
  isTherapyMember: boolean;
  isWebView: boolean;
  hasUnreadChatMessage?: boolean;
  isFirstVisit: boolean;
  chatChannels: User["chatChannels"];
  isRootAccount: boolean;
}

const getNavigationLinks = ({
  isTherapyMember,
  isWebView,
  hasUnreadChatMessage,
  isFirstVisit,
  isRootAccount,
  chatChannels,
}: GetNavigationLinksArgs): NavigationLink[] => {
  const hasUnreadMemberAdvocateMessage =
    !!chatChannels?.MEMBER_ADVOCATE?.unread;

  return [
    {
      id: "therapy",
      title: "Therapy",
      href: `/therapy${isFirstVisit ? "?firstvisit=1" : ""}`,
    },
    {
      id: "community",
      title: "Community",
      href: `/community/posts${isFirstVisit ? "?firstvisit=1" : ""}`,
      hide: isWebView,
      subMenu: (
        <div className="pl-4">
          <TopicButtons />
        </div>
      ),
    },
    {
      id: "messaging",
      title: "Messages",
      href: `/therapy/messages`,
      hide: isWebView,
      shouldShowAlert: hasUnreadMemberAdvocateMessage || hasUnreadChatMessage,
    },
    {
      id: "billing",
      title: "Billing",
      href: "/therapy/billing",
      hide: !isTherapyMember && !isRootAccount,
    },
  ].filter(Boolean) as NavigationLink[];
};

interface GetUserMenuItemsArgs {
  avatarUrl: string;
  communityHandle: string;
  isWebView: boolean;
  handleLogout: () => void;
  canAttendSupportGroups: boolean;
  openSwitchModal: () => void;
  hasLinkedAccounts: boolean;
  isRootAccount: boolean;
  preferredName: string;
}

interface BaseUserMenuItem {
  id: string;
  icon?: ReactNode;
  content: ReactNode;
  hide?: boolean;
  showBottomBorder?: boolean;
  shouldMaintainColor?: boolean;
}

interface ReadOnlyUserMenuItem extends BaseUserMenuItem {
  as: "span";
}

interface LinkUserMenuItem extends BaseUserMenuItem {
  as: "a";
  href: string;
}

interface ButtonUserMenuItem extends BaseUserMenuItem {
  as: "button";
  onClick: () => void;
}

type UserMenuItem =
  | LinkUserMenuItem
  | ButtonUserMenuItem
  | ReadOnlyUserMenuItem;

const getUserMenuItems = ({
  avatarUrl,
  communityHandle,
  isWebView,
  handleLogout,
  canAttendSupportGroups,
  openSwitchModal,
  hasLinkedAccounts,
  isRootAccount,
  preferredName,
}: GetUserMenuItemsArgs): UserMenuItem[] => {
  const result: UserMenuItem[] = [
    {
      id: "view-profile",
      icon: (
        <Avatar
          src={avatarUrl}
          alt={communityHandle}
          className="h-[36px] w-[36px]"
          size="custom"
        />
      ),
      content: (
        <div>
          <p className="font-bold text-gray-900 text-14px">{preferredName}</p>
          <p className="font-bold text-gray-600 text-12px">
            @{communityHandle}
          </p>
          {!isWebView && (
            <p className="text-teal-600 text-12px">View profile</p>
          )}
        </div>
      ),
      as: isWebView ? "span" : "a",
      showBottomBorder: true,
      href: isWebView ? undefined : "/my/profile",
      shouldMaintainColor: true,
    },
  ];

  if (hasLinkedAccounts) {
    result.push({
      id: "view-sub-profile-divider",
      content: (
        <div>
          <p className="text-14px">Switch Profile &rarr;</p>
        </div>
      ),
      as: "button",
      onClick: () => openSwitchModal(),
    });
  }

  result.push(
    {
      id: "billing",
      content: <p>Billing</p>,
      href: "/therapy/billing",
      as: "a",
    },
    {
      id: "account-settings",
      as: "a",
      content: <p>Account settings</p>,
      href: "/my/settings/account",
    },
    {
      id: "notification-settings",
      as: "a",
      content: <p>Notification settings</p>,
      href: "/my/settings/notifications",
      showBottomBorder: true,
    },
    {
      id: "my-documents",
      as: "a",
      content: <p>My documents</p>,
      href: "/therapy/my-documents/documents",
    }
  );

  if (isRootAccount || !hasLinkedAccounts) {
    result.push(
      {
        id: "my-posts",
        as: "a",
        content: <p>My posts</p>,
        href: "/my/posts",
        hide: isWebView,
      },
      {
        id: "my-support-groups",
        as: "a",
        content: <p>My support groups</p>,
        href: "/my/support-groups",
        hide: isWebView || !canAttendSupportGroups,
      },
      {
        id: "share-records",
        as: "a",
        content: <p>Share records</p>,
        href: "/share-records",
        showBottomBorder: true,
        hide: isWebView,
      },
      {
        id: "support",
        content: <p>Need help?</p>,
        href: "/support",
        as: "a",
      }
    );
  }

  result.push({
    id: "log-out",
    as: "button",
    content: <p className="text-red-700">Log out</p>,
    onClick: handleLogout,
    shouldMaintainColor: true,
  });

  return result.filter(Boolean);
};

interface CurrentUserProps {
  isWebView?: boolean;
  openSwitchModal: () => void;
}

const CurrentUser: FC<CurrentUserProps> = ({
  isWebView = false,
  openSwitchModal,
}) => {
  const { data: user, isLoading: isLoadingUser, error: userError } = useUser();
  const { data: session, isLoading: isLoadingSession } = useSession();
  const [_, setIsAuthenticating] = useIsAuthenticating();

  const queryClient = useQueryClient();

  const handleLogout = useCallback(async () => {
    setIsAuthenticating(true);
    await queryClient.cancelQueries();
    await signOut();
  }, [queryClient, setIsAuthenticating]);

  const userMenuItems = useMemo(
    () =>
      getUserMenuItems({
        avatarUrl: user?.avatarUrl,
        communityHandle: user?.communityHandle,
        isWebView,
        handleLogout,
        canAttendSupportGroups:
          user?.isTherapyMember && user?.hasCompletedTherapySession,
        openSwitchModal,
        hasLinkedAccounts: user?.linkedAccounts?.length > 0,
        isRootAccount: user?.isRootAccount,
        preferredName: user?.preferredFirstName,
      }),
    [
      handleLogout,
      user?.avatarUrl,
      isWebView,
      user?.communityHandle,
      user?.isTherapyMember,
      user?.hasCompletedTherapySession,
      openSwitchModal,
      user?.linkedAccounts,
      user?.isRootAccount,
      user?.preferredFirstName,
    ]
  );

  if (!session || isLoadingSession || isLoadingUser || userError || !user) {
    return null;
  }

  return (
    <div className={cn("flex items-center space-x-4", isWebView && "mr-4")}>
      {/**
       * We embed this web app in our mobile apps under a "member portal"
       * tab. When the app is accessed inside of a web view, we don't want
       * the user to be able to see their notifications.
       * */}

      {session.deviceType !== DeviceTypes.WEB_VIEW && (
        <PreviewNotificationsMenu />
      )}

      <Menu as="div" className="relative">
        <Menu.Button className="flex items-center justify-center appearance-none border border-gray-300 rounded-full p-1.5 hover:bg-gray-100 font-poppins">
          {user?.preferredFirstName ? (
            <p className="mr-2">{user?.preferredFirstName}</p>
          ) : null}

          <Avatar
            src={user.avatarUrl}
            alt={user.communityHandle}
            className="h-[36px] w-[36px] desktop:h-[40px] desktop:w-[40px]"
            size="custom"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 px-3 py-4 bg-white rounded-md shadow-md top-16 w-60">
            {userMenuItems
              .filter(({ hide }) => !hide)
              .map((item) => (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <div>
                      {item.as === "button" && (
                        <button
                          type="button"
                          onClick={item.onClick}
                          className={cn(
                            "flex w-full items-center space-x-3 rounded-lg px-3 py-2 text-left text-14px",
                            active && "bg-teal-100",
                            active &&
                              !item.shouldMaintainColor &&
                              "text-teal-600"
                          )}
                        >
                          {item.icon}
                          {item.content}
                        </button>
                      )}

                      {item.as === "span" && (
                        <span
                          className={cn(
                            "flex w-full items-center space-x-3 rounded-lg px-3 py-2 text-left text-14px"
                          )}
                        >
                          {item.icon}
                          {item.content}
                        </span>
                      )}

                      {item.as === "a" && (
                        <NextLinkWithForwardedProps
                          href={item.href}
                          className={cn(
                            "flex w-full items-center space-x-3 rounded-lg px-3 py-2 text-left text-14px",
                            active && "bg-teal-100",
                            active &&
                              !item.shouldMaintainColor &&
                              "text-teal-600"
                          )}
                        >
                          {item.icon}
                          {item.content}
                        </NextLinkWithForwardedProps>
                      )}

                      {item.showBottomBorder ? (
                        <div className="my-1 h-[1px] bg-gray-100" />
                      ) : null}
                    </div>
                  )}
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const getNavShadowClasses = (
  isWindowScrolled: boolean,
  hideShadowOnMobile: boolean,
  hideShadowOnDesktop: boolean
) => {
  if (hideShadowOnMobile && hideShadowOnDesktop) {
    return "shadow-none";
  }

  if (hideShadowOnMobile && !hideShadowOnDesktop) {
    if (isWindowScrolled) {
      return " tablet:shadow-navbar-scrolled";
    }
  }

  if (!hideShadowOnMobile && hideShadowOnDesktop) {
    if (isWindowScrolled) {
      return "shadow-navbar-scrolled tablet:shadow-none";
    }
  }

  if (!hideShadowOnMobile && !hideShadowOnDesktop) {
    if (isWindowScrolled) {
      return "shadow-navbar-scrolled";
    }
  }

  return "shadow-none";
};

export default function AuthenticatedNavBar({
  hideShadowOnMobile = false,
  hideShadowOnDesktop = false,
}: {
  hideShadowOnMobile?: boolean;
  hideShadowOnDesktop?: boolean;
}): JSX.Element {
  const router = useRouter();
  const { data: user } = useUser();
  const { data: therapyHome } = useTherapyHomeData();
  const { data: session } = useSession();

  const [isWindowScrolled, setIsWindowScrolled] = useState<boolean>(false);
  const [isAccountSwitchModalOpen, setIsAccountSwitchModalOpen] =
    useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsWindowScrolled(false);
      } else {
        setIsWindowScrolled(true);
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 500);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, []);

  const navigationLinks = useMemo(
    () =>
      getNavigationLinks({
        hasUnreadChatMessage: therapyHome?.unreadMessagesCount > 0,
        isTherapyMember: user?.isTherapyMember,
        // We embed this web app in our mobile apps under a "member portal" tab.
        // When the app is accessed inside of a web view, we want to hide some
        // of the navigation links.
        isWebView: session?.deviceType === DeviceTypes.WEB_VIEW,
        isFirstVisit: !!router.query.firstvisit,
        isRootAccount: user?.isRootAccount,
        chatChannels: user?.chatChannels,
      }),
    [
      session?.deviceType,
      user?.isTherapyMember,
      therapyHome?.unreadMessagesCount,
      router.query?.firstvisit,
      user?.isRootAccount,
      user?.chatChannels,
    ]
  );

  return (
    <div
      className={cn(
        "duration-600 fixed left-0 right-0 top-0 z-20 bg-white transition-shadow",
        getNavShadowClasses(
          isWindowScrolled,
          hideShadowOnMobile,
          hideShadowOnDesktop
        )
      )}
    >
      <AccountSwitchModal
        isOpen={isAccountSwitchModalOpen}
        handleClose={() => setIsAccountSwitchModalOpen(false)}
        user={user}
      />
      <DesktopMenu
        isTherapyMember={user?.isTherapyMember}
        // We embed this web app in our mobile apps under a "member portal" tab.
        // When the app is accessed inside of a web view, we want to hide some
        // of the navigation links.
        isWebView={session?.deviceType === DeviceTypes.WEB_VIEW}
        navigationLinks={navigationLinks}
        callToAction={
          <CurrentUser
            isWebView={session?.deviceType === DeviceTypes.WEB_VIEW}
            openSwitchModal={() => setIsAccountSwitchModalOpen(true)}
          />
        }
      />

      <MobileMenu
        isTherapyMember={user?.isTherapyMember}
        // We embed this web app in our mobile apps under a "member portal" tab.
        // When the app is accessed inside of a web view, we want to hide some
        // of the navigation links.
        isWebView={session?.deviceType === DeviceTypes.WEB_VIEW}
        callToAction={
          <CurrentUser
            isWebView={session?.deviceType === DeviceTypes.WEB_VIEW}
            openSwitchModal={() => setIsAccountSwitchModalOpen(true)}
          />
        }
        navigationLinks={navigationLinks}
      />
    </div>
  );
}
