import Button from "@core/ui/Button";
import Loader from "@core/ui/Loader";
import { SUPPORTED_POST_TYPES } from "@features/community-v2/constants";
import { usePublicPosts } from "@features/community-v2/hooks/usePublicPosts";
import { PostTypes } from "@features/community-v2/types";
import Link from "next/link";
import { FC, Fragment, useEffect, useState } from "react";

import ActionBar, { useCommunitySearch } from "../ActionBar";
import HighlightedReplyCard from "../HighlightedReplyCard";
import JourneyFeedCard from "../JourneyFeedCard";
import PostCard from "../PostCard";
import PostCarousel from "../PostContent/PostCarousel";
import SubtypeIndicator from "../SubytpeIndicator";
import LoadingFeed from "./LoadingFeed";
import { FeedProps } from "./types";

interface Props extends FeedProps {
  segmentId?: string;
  subtypeSlug?: string;
}

const PublicFeed: FC<Props> = ({
  segmentId,
  subtypeId,
  setError,
  subtypeSlug,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const { data: searchData, isLoading: isSearchLoading } = useCommunitySearch(
    searchTerm,
    null
  );
  const {
    data: publicPosts,
    error,
    isLoading,
  } = usePublicPosts(segmentId, subtypeId);

  useEffect(() => {
    setError(error);
  }, [setError, error]);

  const pathname = subtypeSlug
    ? `/community/topics/${subtypeSlug}`
    : "/community/posts";

  if (isLoading) {
    return <LoadingFeed />;
  }

  return (
    <div className="bg-white rounded-t-xl">
      <ActionBar setSearchTerm={setSearchTerm} />

      {isSearchLoading ? (
        <div className="mx-auto py-20 flex justify-center items-center">
          <Loader />
        </div>
      ) : null}

      {!searchTerm ? (
        <div className="px-6 tablet:hidden">
          <SubtypeIndicator subtypeId={subtypeId} />
        </div>
      ) : null}

      {searchTerm && !searchData?.length && !isSearchLoading ? (
        <div className="mx-auto py-20 flex justify-center items-center">
          It looks like there are no results for your search. Please try again
          with a different search term.
        </div>
      ) : null}

      {searchTerm && searchData?.length ? (
        <div className="bg-white divide-y divide-y-200">
          {searchData?.map((post) => (
            <Fragment key={`${post?.post?.thread_id}`}>
              <section id={`${post?.post?.thread_id}`} className="py-6 px-5">
                <div className="flex items-start">
                  <div className="flex-grow">
                    <PostCard
                      id={String(post.post.thread_id)}
                      onClickReply={{
                        type: "link",
                        href: `/community/posts/${post?.post?.thread_id}`,
                      }}
                      post={post.post}
                      rootPostId={post?.post?.thread_id}
                      variant="feed"
                    />
                  </div>
                </div>
              </section>
            </Fragment>
          ))}
        </div>
      ) : null}

      {
        // Remove any post types that we don't have UI to support. The
        // API is also consumed by our iOS and Android apps. It's possible
        // that there will be some drift between clients.
        !searchTerm ? (
          <div className="bg-white divide-y divide-gray-200">
            {publicPosts?.data
              .filter(({ post_type }) =>
                SUPPORTED_POST_TYPES.includes(post_type)
              )
              .map((post, postIndex) => {
                const shouldShowJourneyFeedCard = postIndex === 5;

                const isHighlightedReply =
                  post.post_type === PostTypes.TEXT_REPLY &&
                  post.post_type_data?.highlight_as_post;

                if (post.post_type === PostTypes.POST_CAROUSEL) {
                  return (
                    <Fragment key={`${post.id}`}>
                      {shouldShowJourneyFeedCard && (
                        <JourneyFeedCard subtypeId={subtypeId} />
                      )}
                      <section id={`${post.id}`} className="py-6 px-5">
                        <PostCarousel
                          items={post.post_type_data.carousel_items}
                        />
                      </section>
                    </Fragment>
                  );
                }

                if (isHighlightedReply) {
                  return (
                    <Fragment key={`${post.id}`}>
                      {shouldShowJourneyFeedCard && (
                        <JourneyFeedCard subtypeId={subtypeId} />
                      )}
                      <section
                        key={`${post.id}`}
                        className="py-6 px-5"
                        id={`${post.id}`}
                      >
                        <HighlightedReplyCard
                          post={post}
                          rootPostId={post.id}
                          variant="feed"
                        />
                      </section>
                    </Fragment>
                  );
                }

                return (
                  <Fragment key={`${post.id}`}>
                    {shouldShowJourneyFeedCard && (
                      <JourneyFeedCard subtypeId={subtypeId} />
                    )}

                    <section id={`${post.id}`} className="py-6 px-5">
                      <div className="flex items-start">
                        <div className="flex-grow">
                          <PostCard
                            id={String(post.id)}
                            onClickReply={{
                              type: "link",
                              href: `/community/posts/${post.id}`,
                            }}
                            post={post}
                            rootPostId={post.id}
                            variant="feed"
                          />
                        </div>
                      </div>
                    </section>
                  </Fragment>
                );
              })}
          </div>
        ) : null
      }

      {!searchTerm
        ? // This is needed so search engines can paginate through the feed.
          publicPosts?.cursors?.next && (
            <div className="flex py-4 cursor-pointer tablet:justify-center">
              <Link
                href={{
                  query: { segmentId: publicPosts.cursors.next },
                  pathname,
                }}
                scroll
                passHref
              >
                <Button
                  as="a"
                  className="hidden items-center font-bold tablet:flex"
                >
                  Go to next page
                </Button>
              </Link>

              <Link
                href={{
                  query: { segmentId: publicPosts.cursors.next },
                  pathname,
                }}
                scroll
                passHref
              >
                <Button
                  as="a"
                  variant="text"
                  className="block items-center mx-auto font-bold tablet:hidden"
                >
                  Go to next page
                </Button>
              </Link>
            </div>
          )
        : null}
    </div>
  );
};

export default PublicFeed;
