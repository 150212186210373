import useModalWithDelayedCallback from "@core/hooks/useModalWithDelayedCallback";
import usePrompt from "@core/hooks/usePrompt";
import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import ErrorScreen from "@core/layout/ErrorScreen";
import { SSRError } from "@core/types";
import Avatar from "@core/ui/Avatar";
import Button from "@core/ui/Button";
import Input from "@core/ui/Input";
import Loader from "@core/ui/Loader";
import Modal from "@core/ui/Modal";
import Textarea from "@core/ui/Textarea";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouterScroll } from "@moxy/next-router-scroll";
import cn from "classnames";
import { isEmpty, throttle, trim, truncate } from "lodash";
import Head from "next/head";
import Link from "next/link";
import { NextSeo } from "next-seo";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Scroll from "react-scroll";
import * as yup from "yup";

import ExitPromptModal from "../components/ExitPromptModal";
import PageWrapper from "../components/PageWrapper";
import PostCard from "../components/PostCard";
import RecentPostsSidebar from "../components/RecentPostsSidebar";
import UnsavedChangesWatcherCommunity from "../components/UnsavedChangesWatcherCommunity";
import { useMutatePostReply } from "../hooks/useMutatePostReply";
import { usePost, useRelatedPosts } from "../hooks/usePost";
import { PostAvatarSizes, PostExt, PostId, PostTypes } from "../types";

const { scroller } = Scroll;

const FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS =
  "https://assets.treatmyocd.com/images/avatars/avatar_0_circle.png";

const getMetaTitle = (
  post: PostExt,
  topicsPartOfMetaTitle: string,
  isAuthenticated: boolean
) => {
  if (!isAuthenticated && post.generated_title) {
    return post.generated_title;
  }

  return (
    post?.seo_title ||
    post?.posting_title ||
    truncate(`${topicsPartOfMetaTitle}${post?.body}`, {
      length: 63,
    })
  );
};

const getAvatarSizeByDepth = (depth: number) => {
  switch (depth) {
    case 0:
      return PostAvatarSizes.LARGE;
    case 1:
      return PostAvatarSizes.MEDIUM;
    default:
      return PostAvatarSizes.SMALL;
  }
};

const schema = yup.object({
  replyBody: yup.string().required(),
});

interface FormValues {
  replyBody: string;
}

interface PostThreadScreenProps {
  postId: PostId;
  ssrError: SSRError;
}

export function PostThreadScreen({
  postId,
  ssrError,
}: PostThreadScreenProps): JSX.Element {
  const { openModal } = useAuthenticationModal();

  const {
    data: post,
    isLoading: isLoadingPosts,
    error: postError,
  } = usePost(postId);
  const { mutateAsync: replyToPost } = useMutatePostReply();
  const [postIdToReplyTo, setPostIdToReplyTo] = useState<number>(null);

  const {
    isOpen: isReplyDialogOpen,
    open: openReplyDialog,
    close: closeReplyDialog,
  } = useModalWithDelayedCallback();

  const {
    data: session,
    isLoading: isLoadingSession,
    error: sessionError,
  } = useSession();
  const { data: user, isLoading: isLoadingUser, error: userError } = useUser();

  const { data: relatedPosts } = useRelatedPosts(postId);

  const [isWindowScrolled, setIsWindowScrolled] = useState(false);

  const inlineReplyForm = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register: registerInlineReplyForm,
    formState: inlineReplyFormState,
    reset: resetInlineReplyForm,
  } = inlineReplyForm;
  const {
    isSubmitting: isSubmittingInlineReplyForm,
    isValid: isInlineReplyFormValid,
  } = inlineReplyFormState;

  const pinnedInlineReplyForm = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register: registerPinnedInlineReplyForm,
    formState: pinnedInlineReplyFormState,
    reset: resetPinnedInlineReplyForm,
  } = pinnedInlineReplyForm;
  const {
    isSubmitting: isSubmittingPinnedInlineReplyForm,
    isValid: isPinnedInlineReplyFormValid,
  } = pinnedInlineReplyFormState;

  const modalReplyForm = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register: registerModalReplyForm,
    formState: modalReplyFormState,
    reset: resetModalReplyForm,
  } = modalReplyForm;
  const {
    isSubmitting: isSubmittingModalReplyForm,
    isValid: isModalReplyFormValid,
  } = modalReplyFormState;

  const prompt = usePrompt<boolean>();

  const handleSafeClose = async () => {
    if (isModalReplyFormValid) {
      const shouldClose = await prompt.prompt();
      if (shouldClose) {
        closeReplyDialog(() => {
          setPostIdToReplyTo(null);
          resetModalReplyForm();
        });
      }
    } else {
      closeReplyDialog(() => {
        setPostIdToReplyTo(null);
        resetModalReplyForm();
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
  const { updateScroll } = useRouterScroll();
  const ref = useCallback(
    (node) => {
      if (node) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        updateScroll();
      }
    },
    [updateScroll]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 600) {
        setIsWindowScrolled(false);
      } else {
        setIsWindowScrolled(true);
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, []);

  if (userError || sessionError || postError || ssrError) {
    return (
      <ErrorScreen error={userError ?? sessionError ?? postError ?? ssrError} />
    );
  }

  const onSubmitInlineReplyForm = inlineReplyForm.handleSubmit(
    ({ replyBody }: FormValues) =>
      replyToPost({
        postId: post.post.id,
        replyId: post.post.id,
        body: replyBody,
        queryKeyPostId: postId,
      }).then((newPost) => {
        resetInlineReplyForm();
        resetPinnedInlineReplyForm();

        return scroller.scrollTo(`${newPost.id}`, {
          duration: 500,
          delay: 100,
          smooth: true,
          offset: -150,
        });
      })
  );

  const onSubmitPinnedInlineReplyForm = pinnedInlineReplyForm.handleSubmit(
    ({ replyBody }: FormValues) =>
      replyToPost({
        postId: post.post.id,
        replyId: post.post.id,
        body: replyBody,
        queryKeyPostId: postId,
      }).then((newPost) => {
        resetInlineReplyForm();
        resetPinnedInlineReplyForm();

        return scroller.scrollTo(`${newPost.id}`, {
          duration: 500,
          delay: 100,
          smooth: true,
          offset: -150,
        });
      })
  );

  const postToReplyTo =
    postIdToReplyTo === post?.post?.id
      ? post?.post
      : post?.replies?.find(({ id }) => postIdToReplyTo === id);

  const onSubmitModalReplyForm = modalReplyForm.handleSubmit(
    ({ replyBody }: FormValues) =>
      replyToPost({
        postId: post.post.id,
        // We only support posts up to a depth of 2.
        replyId:
          postToReplyTo?.db_depth > 1
            ? postToReplyTo.post_replied_to
            : postToReplyTo.id,
        body:
          // When it's a depth 2 post, we append the post author's handle
          // to the comment.
          postToReplyTo?.db_depth > 1
            ? `@${postToReplyTo?.community_handle} - ${replyBody}`
            : replyBody,
        queryKeyPostId: postId,
      }).then((newPost) => {
        closeReplyDialog(() => {
          setPostIdToReplyTo(null);
          resetModalReplyForm();
        });

        return scroller.scrollTo(`${newPost.id}`, {
          duration: 500,
          delay: 100,
          smooth: true,
          offset: -150,
        });
      })
  );

  // Construct a title that looks like:
  // Sexual Orientation OCD, POCD: Hi My name is Stephen Smith and I'm the founder….
  const topics = [
    ...(post?.post?.topics ?? []).map(({ topic_title }) => topic_title),
    post?.post?.post_type === PostTypes.THERAPY_SESSION_REVIEW
      ? "Therapy Review"
      : null,
  ].filter(Boolean);
  const topicsPartOfMetaTitle = isEmpty(topics) ? "" : `${topics.join(", ")}: `;

  const metaTitle = getMetaTitle(post?.post, topicsPartOfMetaTitle, !!session);

  const metaDescription =
    post?.post?.seo_description ||
    truncate(trim(post?.post?.body), { length: 160 });

  const canonical = `${process.env.NEXT_PUBLIC_CANONICAL_URL}/community/posts/${postId}`;

  const isLoading = isLoadingPosts || isLoadingSession || isLoadingUser;

  return (
    <div className="flex-1 relative">
      <div className="relative z-1">
        <PageWrapper
          classes={{ container: "tablet:pt-16" }}
          ref={ref}
          hideSidebar
          hideHero
        >
          <style jsx>{`
            .full-width {
              width: 100vw;
              position: relative;
              left: 50%;
              right: 50%;
              margin-left: -50vw;
              margin-right: -50vw;
            }
          `}</style>

          <Link href="/community/posts" passHref>
            <a
              type="button"
              className="tablet:text-18px font-bold hidden tablet:inline"
            >
              &larr; Explore more posts
            </a>
          </Link>

          <div className="tablet:mt-6 pb-24 laptop:grid laptop:grid-cols-3 laptop:gap-x-6">
            {isLoading ? (
              <main className="laptop:col-span-2">
                <div className="flex justify-center items-center py-16 text-indigo-600 text-32px">
                  <Loader />
                </div>
              </main>
            ) : (
              <main className="laptop:col-span-2">
                <NextSeo
                  title={metaTitle}
                  description={metaDescription}
                  openGraph={{
                    description: metaDescription,
                    title: metaTitle,
                  }}
                  noindex={!post.post.indexable}
                  canonical={canonical}
                />

                {!session ? (
                  <Head>
                    <script
                      type="application/ld+json"
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                          "@context": "https://schema.org",
                          "@type": "DiscussionForumPosting",
                          headline: metaTitle,
                          text: metaDescription,
                          mainEntityOfPage: canonical,
                          url: canonical,
                          author: {
                            "@type": "Person",
                            name: post.post.community_handle,
                          },
                          datePublished: post.post.created_at,
                          interactionStatistic: {
                            "@type": "InteractionCounter",
                            interactionType: "https://schema.org/LikeAction",
                            userInteractionCount: post.post.likes,
                          },
                          commentCount: post.replies.length,
                          comment: post.replies.map((reply) => ({
                            "@type": "Comment",
                            text: reply.body,
                            datePublished: reply.created_at,
                            author: {
                              "@type": "Person",
                              name: reply.community_handle,
                            },
                            interactionStatistic: {
                              "@type": "InteractionCounter",
                              interactionType: "https://schema.org/LikeAction",
                              userInteractionCount: reply.likes,
                            },
                          })),
                        }),
                      }}
                    />
                  </Head>
                ) : null}

                <section
                  className={cn(
                    "bg-white tablet:rounded-2xl pb-6 pt-12 tablet:pt-6 px-4 tablet:mx-0",
                    "tablet:px-6 tablet:py-7.5 border border-gray-200 shadow-sm"
                  )}
                >
                  <Link href="/community/posts" passHref>
                    <a className="tablet:text-18px font-bold tablet:hidden relative inline-block mb-4">
                      &larr; Explore more posts
                    </a>
                  </Link>

                  <div className={cn("relative")}>
                    <PostCard
                      redirectAfterDeletion
                      id={`${post.post.id}`}
                      onClickReply={{
                        type: "button",
                        handler: (selectedPostId) => {
                          if (!session) {
                            openModal({
                              redirectAfterAuthentication: false,
                              variant: "community",
                            });
                            return;
                          }

                          setPostIdToReplyTo(selectedPostId);
                          openReplyDialog();
                        },
                      }}
                      variant="single"
                      post={post.post}
                      rootPostId={post.post.id}
                    />
                  </div>

                  <div
                    onClick={
                      session
                        ? undefined
                        : (event) => {
                            event.stopPropagation();

                            return openModal({
                              redirectAfterAuthentication: false,
                              variant: "community",
                            });
                          }
                    }
                    className={cn(
                      "fixed z-10 top-14 tablet:top-20 left-0 right-0 bg-white tablet:bg-opacity-0 py-6 tablet:py-0 shadow-md tablet:shadow-none",
                      "mx-auto px-4 transition-opacity laptop:grid laptop:max-w-[1072px] laptop:grid-cols-3 laptop:gap-x-6 laptop:px-6",
                      isWindowScrolled
                        ? "opacity-100"
                        : "pointer-events-none opacity-0"
                    )}
                  >
                    <form
                      onSubmit={onSubmitPinnedInlineReplyForm}
                      className={cn(
                        "col-span-2 tablet:px-6",
                        !session && "pointer-events-none"
                      )}
                    >
                      <div className="flex items-center">
                        <div className="flex flex-1 items-center space-x-2 p-4 rounded-2xl border border-indigo-300 bg-white tablet:shadow">
                          <Input
                            backgroundColor="white"
                            className="flex-1 !border-0 !p-0 !text-gray-900 !ring-0"
                            placeholder="Add your comment..."
                            {...registerPinnedInlineReplyForm("replyBody")}
                          />
                          <button
                            disabled={isSubmittingPinnedInlineReplyForm}
                            type="submit"
                          >
                            <dl>
                              <dd className="sr-only">Reply</dd>
                              <dt>
                                <p
                                  className={cn(
                                    "group-disabled:text-[#CBD5E1] text-15px font-bold leading-[19.95px] text-indigo-600",
                                    !isPinnedInlineReplyFormValid && "invisible"
                                  )}
                                >
                                  Post
                                </p>
                              </dt>
                            </dl>
                          </button>
                        </div>
                      </div>
                      <UnsavedChangesWatcherCommunity
                        isDirty={isPinnedInlineReplyFormValid}
                      />
                    </form>
                    <div className="hidden laptop:block" />
                  </div>

                  <div
                    className="mt-6"
                    onClick={
                      session
                        ? undefined
                        : (event) => {
                            event.stopPropagation();

                            return openModal({
                              redirectAfterAuthentication: false,
                              variant: "community",
                            });
                          }
                    }
                  >
                    <form
                      onSubmit={onSubmitInlineReplyForm}
                      className={cn(
                        "static bg-white z-10",
                        // "rounded-2xl border-t border shadow-sm mt-4.5 border-gray-200",
                        !session && "pointer-events-none"
                      )}
                    >
                      <div className="space-y-3">
                        <div className="flex gap-4 items-center">
                          <Avatar
                            src={
                              user?.avatarUrl ??
                              FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS
                            }
                            alt={user?.communityHandle}
                            size="custom"
                            className="h-[3rem] w-[3rem] flex-shrink-0"
                          />

                          <p className="font-bold">You</p>
                        </div>

                        <div className="bg-white flex-grow rounded-2xl overflow-hidden border border-indigo-300">
                          <Textarea
                            classes={{
                              root: "flex-1",
                              input:
                                "bg-white focus:!ring-0 !border-0 focus:!border-0 focus:!shadow-none",
                            }}
                            label="Add comment"
                            hideLabel
                            id="replyBody"
                            minRows={2}
                            placeholder="Add your comment..."
                            {...registerInlineReplyForm("replyBody")}
                          />

                          <div className="flex justify-end px-4 pb-4">
                            <button
                              disabled={isSubmittingInlineReplyForm}
                              type="submit"
                              className="text-18px font-medium"
                            >
                              <dl>
                                <dd className="sr-only">Reply</dd>
                                <dt>
                                  <p
                                    className={cn(
                                      "group-disabled:text-[#CBD5E1] text-15px font-bold leading-[19.95px] text-indigo-600",
                                      !isInlineReplyFormValid && "invisible"
                                    )}
                                  >
                                    Post
                                  </p>
                                </dt>
                              </dl>
                            </button>
                          </div>
                        </div>
                      </div>
                      <UnsavedChangesWatcherCommunity
                        isDirty={isInlineReplyFormValid}
                      />
                    </form>
                  </div>
                </section>

                <div className="relative mt-4">
                  <div className="mt-4.5 tablet:mt-0 space-y-4.5 relative px-4 tablet:px-0">
                    {post?.replies.map((reply) => {
                      return (
                        <div
                          id={`${reply.id}`}
                          className={cn(
                            "relative border border-gray-200 shadow-sm",
                            "bg-white p-4 rounded-2xl",
                            reply.db_depth > 1 && "ml-4 tablet:ml-6"
                          )}
                          key={`${reply.id}`}
                        >
                          <PostCard
                            variant="reply"
                            avatarSize={getAvatarSizeByDepth(reply.db_depth)}
                            onClickReply={{
                              type: "button",
                              handler: (selectedPostId) => {
                                if (!session) {
                                  openModal({
                                    redirectAfterAuthentication: false,
                                    variant: "community",
                                  });
                                  return;
                                }

                                setPostIdToReplyTo(selectedPostId);
                                openReplyDialog();
                              },
                            }}
                            post={reply}
                            rootPostId={post.post.id}
                          />
                        </div>
                      );
                    })}
                  </div>

                  {relatedPosts?.length ? (
                    <div className="mt-6 relative space-y-4">
                      <h2 className="font-bold relative z-1">Related posts</h2>
                      {relatedPosts?.map((relatedPost) => (
                        <div
                          key={relatedPost.id}
                          className="p-4 bg-white rounded-2xl border border-gray-200 shadow-sm"
                        >
                          <PostCard
                            variant="related"
                            post={relatedPost}
                            rootPostId={post.post.id}
                            id={`${relatedPost.id}`}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}

                  {!session ? (
                    <>
                      <div className="full-width tablet:hidden">
                        <div
                          className="text-center px-12 pb-22 pt-16"
                          style={{
                            background:
                              "linear-gradient(180deg, #F8FBFF 0%, rgba(248, 251, 255, 0.00) 100%), linear-gradient(180deg, rgba(248, 251, 255, 0.00) 76.64%, #F8FBFF 100%), linear-gradient(226deg, rgba(255, 255, 255, 0.00) 23.21%, #E7D5FE 72.85%), linear-gradient(21deg, rgba(84, 92, 234, 0.60) 33.36%, rgba(80, 169, 251, 0.60) 61.66%, rgba(252, 226, 182, 0.60) 85.94%), #FFF",
                          }}
                        >
                          <h2 className="font-bold text-22px">
                            Be a part of the largest OCD Community
                          </h2>

                          <p className="mt-3 mb-5">
                            Share your thoughts so the Community can respond
                          </p>

                          <div className="flex justify-center">
                            <Button
                              color="indigo"
                              className="!font-semibold !rounded-lg !py-4 !px-6"
                              onClick={() => openModal()}
                            >
                              Create a free account
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="text-center px-12 pb-22 pt-16 hidden tablet:block"
                        style={{
                          background:
                            "linear-gradient(180deg, #F8FBFF 0%, rgba(248, 251, 255, 0.00) 100%), linear-gradient(180deg, rgba(248, 251, 255, 0.00) 76.64%, #F8FBFF 100%), linear-gradient(226deg, rgba(255, 255, 255, 0.00) 23.21%, #E7D5FE 72.85%), linear-gradient(21deg, rgba(84, 92, 234, 0.60) 33.36%, rgba(80, 169, 251, 0.60) 61.66%, rgba(252, 226, 182, 0.60) 85.94%), #FFF",
                          backgroundSize: "contain",
                        }}
                      >
                        <h2 className="font-bold text-22px">
                          Be a part of the largest OCD Community
                        </h2>

                        <p className="mt-3 mb-5">
                          Share your thoughts so the Community can respond
                        </p>

                        <div className="flex justify-center">
                          <Button
                            color="indigo"
                            className="!font-semibold !rounded-lg !py-4 !px-6"
                            onClick={() => openModal()}
                          >
                            Create a free account
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </main>
            )}

            <div className="hidden laptop:block">
              <RecentPostsSidebar currentPostId={Number(postId)} />
            </div>
          </div>

          {!isLoading && !!session ? (
            <Modal isOpen={isReplyDialogOpen} onClose={handleSafeClose}>
              <Modal.CloseButton onClick={handleSafeClose} />
              {postIdToReplyTo ? (
                <>
                  <div className="relative">
                    <PostCard
                      hideActions
                      rootPostId={post.post.id}
                      post={postToReplyTo}
                      variant="single"
                    />
                  </div>

                  <form onSubmit={onSubmitModalReplyForm}>
                    <div className="tablet:mb-6 flex items-start space-x-2.5 py-4">
                      <Avatar
                        src={
                          user?.avatarUrl ??
                          FALLBACK_AVATAR_URL_FOR_UNAUTHENTICATED_USERS
                        }
                        alt={user?.communityHandle}
                        size="custom"
                        className="h-[32px] w-[32px] flex-shrink-0 tablet:h-[40px] tablet:w-[40px]"
                      />

                      <div className="bg-white flex-grow rounded-2xl overflow-hidden border border-indigo-300">
                        <Textarea
                          classes={{
                            root: "flex-1",
                            input:
                              "bg-white focus:!ring-0 !border-0 focus:!border-0 focus:!shadow-none",
                          }}
                          label="Add comment"
                          hideLabel
                          id="replyBody"
                          minRows={2}
                          maxRows={5}
                          placeholder="Add your comment..."
                          {...registerModalReplyForm("replyBody")}
                        />

                        <div className="flex justify-end px-4 pb-4">
                          <button
                            disabled={isSubmittingModalReplyForm}
                            type="submit"
                            className="text-18px font-medium"
                          >
                            <dl>
                              <dd className="sr-only">Reply</dd>
                              <dt>
                                <p
                                  className={cn(
                                    "group-disabled:text-[#CBD5E1] text-15px font-bold leading-[19.95px] text-indigo-600",
                                    !isModalReplyFormValid && "invisible"
                                  )}
                                >
                                  Post
                                </p>
                              </dt>
                            </dl>
                          </button>
                        </div>
                      </div>
                    </div>
                    <UnsavedChangesWatcherCommunity
                      isDirty={isModalReplyFormValid}
                    />
                  </form>
                </>
              ) : null}

              <ExitPromptModal
                isOpen={prompt.isOpen}
                onClose={() => prompt.onConfirm(false)}
                onConfirm={() => prompt.onConfirm(true)}
              />
            </Modal>
          ) : null}
        </PageWrapper>
      </div>
    </div>
  );
}
