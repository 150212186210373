import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

import PostCard, { PostCardProps } from "./PostCard";

const HighlightedReplyCard: FC<PostCardProps> = ({ post }) => {
  return (
    <Link
      href={`/community/posts/${post.post_replied_to}#${post.post_type_data.post_id}`}
    >
      <a>
        <div className="pointer-events-none">
          {post.post_type_data.labels?.map((label) => (
            <span
              key={label.name}
              className="rounded-lg px-2 py-[2px] inline-block text-14px tablet:text-12px"
              style={{
                backgroundColor: `#${label.background_color}`,
                color: `#${label.text_color}`,
              }}
            >
              {label.name}
            </span>
          ))}

          <>
            <div className="py-2 flex items-center gap-2">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className="w-8 h-8"
                src={post.post_type_data.title_icon_url}
                alt=""
              />

              <p className="font-bold text-18px">{post.post_type_data.title}</p>
            </div>
          </>
          <div className="mt-1 tablet:mt-2 flex items-start">
            <div className="px-2 tablet:px-4 flex-shrink-0">
              <div className="hidden tablet:block pt-4">
                <Image
                  src="/images/community/highlighted-reply-arrow.svg"
                  width={24}
                  height={30}
                  alt=""
                />
              </div>

              <div className="tablet:hidden pt-6">
                <Image
                  src="/images/community/highlighted-reply-arrow.svg"
                  width={11}
                  height={12}
                  alt=""
                />
              </div>
            </div>

            <div className="flex-grow">
              <PostCard
                id={String(post.id)}
                onClickReply={{
                  type: "link",
                  href: `/community/posts/${post.post_replied_to}#${post.post_type_data.post_id}`,
                }}
                post={post}
                rootPostId={`${post.post_replied_to}#${post.post_type_data.post_id}`}
                variant="feed"
              />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default HighlightedReplyCard;
