import Button from "@core/ui/Button";
import Modal from "@core/ui/Modal";
import Router from "next/router";
import { useState } from "react";
import toast from "react-hot-toast";

import { useMutatePostAction } from "../hooks/useMutatePostAction";
import { Post, PostActionIds, PostExt, RootPostId } from "../types";

interface DeletePostDialogProps {
  post: Post | PostExt;
  onClose: () => void;
  isOpen: boolean;
  rootPostId: RootPostId;
  redirectAfterDeletion: boolean;
}

export default function DeletePostDialog({
  post,
  onClose,
  isOpen,
  rootPostId,
  redirectAfterDeletion,
}: DeletePostDialogProps): JSX.Element {
  const { mutateAsync: performPostAction } = useMutatePostAction(rootPostId);
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Modal.CloseButton onClick={onClose} />
      <h2 className="mb-4 text-left text-24px font-bold">Delete post?</h2>

      <p className="mb-8 text-20px">
        This can&apos;t be undone. Your post will be permanently removed from
        the community.
      </p>

      <div className="grid gap-x-4 gap-y-4 tablet:grid-cols-2">
        <Button color="gray" className="text-20px" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={isDeleting}
          className="text-20px"
          onClick={() => {
            setIsDeleting(true);

            return performPostAction({
              actionId: PostActionIds.DELETE_POST,
              postId: post.id,
              isEnabled: null,
              userId: null,
            })
              .then(
                () =>
                  redirectAfterDeletion && Router.replace("/community/posts")
              )
              .then(() => toast.success("Post deleted"))
              .catch((error: Error) => {
                setIsDeleting(false);
                return toast.error(error.message);
              });
          }}
          color="red"
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
}
