import { XIcon } from "@heroicons/react/solid";
import Link from "next/link";

import { useTopicById } from "../hooks/useSubtypes";

export default function SubtypeIndicator({ subtypeId }: { subtypeId: string }) {
  const { data: subtype } = useTopicById(subtypeId);

  if (!subtype) {
    return null;
  }

  return (
    <Link passHref href="/community/posts">
      <a className="bg-teal-200 border-teal-600 text-14px border items-center text-teal-600 font-semibold rounded-full px-2 py-1 inline-flex gap-1">
        <span>{subtype?.title}</span>

        <XIcon className="w-4 h-4" />
      </a>
    </Link>
  );
}
