import Image from "next/image";
import { ReactNode } from "react";

interface Journey {
  subtype: string;
  imageUrl: string;
  authorName: string;
  description: ReactNode;
  url: string;
}

const JOURNEY_MAP: { [key: string]: Journey } = {
  "5d3d06a1": {
    subtype: "Existential OCD",
    description: (
      <>
        It can feel like stepping into a bizarre version of the world, and all
        you want to do is get out.
      </>
    ),
    authorName: "Michael Corey",
    imageUrl: "/images/community/journey-cards/subtype-images/existential.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/take-care-of-michael`,
  },
  "0087ea50": {
    subtype: "False-Memory OCD",
    description: (
      <>
        I was consumed with all sorts of “what-ifs” and how to prevent anything
        dangerous that would have potentially been my fault.
      </>
    ),
    authorName: "Sarah",
    imageUrl: "/images/community/journey-cards/subtype-images/false-memory.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/the-day-my-brain-broke`,
  },
  deccf50e: {
    subtype: "Harm OCD",
    description: (
      <>
        I was screaming for help, and no one listened. My OCD told me that it
        was all my fault. Believe me when I say that it’s never too late to get
        the help you deserve.
      </>
    ),
    authorName: "Anonymous",
    imageUrl: "/images/community/journey-cards/subtype-images/harm.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/learning-to-live-with-ocd-2`,
  },
  d9381040: {
    subtype: "Health and Contamination OCD",
    description: (
      <>
        Everyone I knew with anxiety seemed to be so different from me. I have
        never just had anxiety out of nowhere. There is always a specific fear
        behind it, no matter how irrational.
      </>
    ),
    authorName: "Erika",
    imageUrl:
      "/images/community/journey-cards/subtype-images/contamination.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/cancel-out-the-thoughts`,
  },
  "23de3c98": {
    subtype: "Perfectionism OCD",
    description: (
      <>
        Most of my suffering was subtle or silent. The OCD that plagued me was
        mostly in my mind.
      </>
    ),
    authorName: "Grace Anderson",
    imageUrl:
      "/images/community/journey-cards/subtype-images/perfectionism.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/the-unsolvable-puzzle-my-experience-with-ocd`,
  },
  a7b178bf: {
    subtype: "Relationship OCD",
    description: (
      <>
        I had to learn to live in the “maybe, maybe not”. At this point, I know
        I can face anything. I know that I can fight the fight. I know that I
        will be triumphant over this.
      </>
    ),
    authorName: "Chelsea R",
    imageUrl: "/images/community/journey-cards/subtype-images/relationship.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/living-in-the-maybes`,
  },
  cd5871d2: {
    subtype: "Religious OCD",
    description: (
      <>
        Now I&apos;m able to recognize that a regular standard of obedience is
        much different than OCD&apos;s standard of extreme and impossible
        obedience. I often told my husband that I felt like a bird in a cage.
      </>
    ),
    authorName: "Erica Richardson",
    imageUrl: "/images/community/journey-cards/subtype-images/religious.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/trapped-like-a-bird-in-a-cage`,
  },
  aee5f3bc: {
    subtype: "Somatic OCD",
    description: (
      <>
        I used to lay in bed in the morning and sob, begging my mom to let me
        sleep so I didn&apos;t have to face my symptoms.
      </>
    ),
    authorName: "Haley Biddanda",
    imageUrl: "/images/community/journey-cards/subtype-images/somatic.png",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/sensorimotor-ocd-breathing-and-breathing-and-breathing-through-the-panic`,
  },
  a1b58b89: {
    subtype: "Sexual and Gender OCD",
    description: (
      <>
        OCD is a bittersweet reminder of your morals and values, accept and
        embrace it.
      </>
    ),
    imageUrl: "/images/community/journey-cards/subtype-images/sexual.png",
    authorName: "Shaun Flores",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/harm-ocd-sexual-orientation-ocd-model-influencer-and-ocd-advocate`,
  },
  "6c1d2059": {
    subtype: "Sexual and Gender OCD",
    description: (
      <>
        OCD is a bittersweet reminder of your morals and values, accept and
        embrace it.
      </>
    ),
    imageUrl: "/images/community/journey-cards/subtype-images/sexual.png",
    authorName: "Shaun Flores",
    url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/my-ocd-journey/harm-ocd-sexual-orientation-ocd-model-influencer-and-ocd-advocate`,
  },
};

const LogoSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="6.13725" cy="6.13627" rx="2.04545" ry="2.04545" fill="white" />
    <ellipse cx="13.8638" cy="6.13627" rx="2.04545" ry="2.04545" fill="white" />
    <ellipse cx="10.2271" cy="2.04545" rx="2.04545" ry="2.04545" fill="white" />
    <ellipse cx="10.2271" cy="10.2273" rx="2.04545" ry="2.04545" fill="white" />
    <ellipse cx="17.9537" cy="10.2273" rx="2.04545" ry="2.04545" fill="white" />
    <circle cx="10.2271" cy="17.9546" r="2.04545" fill="white" />
    <circle cx="2.04545" cy="10.2273" r="2.04545" fill="white" />
    <circle cx="13.8638" cy="13.8636" r="2.04545" fill="white" />
    <ellipse cx="6.13725" cy="13.8636" rx="2.04545" ry="2.04545" fill="white" />
  </svg>
);

export default function JourneyFeedCard({ subtypeId }: { subtypeId: string }) {
  const journey = JOURNEY_MAP?.[subtypeId] || null;

  if (!journey) return null;

  return (
    <div
      className="flex gap-5 items-center relative py-7 px-6 tablet:px-16 overflow-hidden"
      style={{
        background: "url('/images/community/journey-cards/bg.svg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom left",
      }}
    >
      <div className="hidden tablet:block z-0 overflow-hidden absolute bottom-0 -right-1 text-[0px]">
        <Image
          src={journey.imageUrl}
          alt=""
          width={190}
          height={225}
          quality={100}
          aria-hidden="true"
        />
      </div>

      <div className="tablet:hidden z-0 overflow-hidden absolute bottom-0 -right-1 text-[0px]">
        <Image
          src={journey.imageUrl}
          alt=""
          width={100}
          height={123}
          quality={100}
          aria-hidden="true"
        />
      </div>

      <div className="relative z-1 grid">
        <div className="flex gap-2 items-center text-white">
          <LogoSvg />

          <p className="font-semibold text-12px tablet:text-14px">
            OCD Journey Stories
          </p>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="7"
            viewBox="0 0 6 7"
            fill="none"
          >
            <path
              d="M5.72727 3.49645C5.72727 5.07799 4.44518 6.36008 2.86364 6.36008C1.28209 6.36008 0 5.07799 0 3.49645C0 1.91491 1.28209 0.632812 2.86364 0.632812C4.44518 0.632812 5.72727 1.91491 5.72727 3.49645Z"
              fill="white"
              fill-opacity="0.5"
            />
          </svg>

          <p className="opacity-40 text-12px tablet:text-14px">Promoted</p>
        </div>

        <div className="mt-7 flex gap-4 items-start relative z-1 max-w-[275px] tablet:max-w-[375px]">
          <div>
            <div className="tablet:min-h-30 flex justify-center items-center gap-4">
              <div className="flex-shrink-0">
                <Image
                  src="/images/community/journey-cards/bar.svg"
                  alt=""
                  width="4"
                  height="120"
                />
              </div>

              <p className="text-white line-clamp-5 font-medium">
                {journey.description}
              </p>
            </div>

            <p className="text-12px tablet:text-14px text-white opacity-60 mt-2">
              By {journey.authorName}
            </p>

            <a
              href={journey.url}
              className="inline-block font-semibold text-teal-600 mt-8 tablet:mt-3 text-12px tablet:text-14px max-w-[200px] phone:max-w-none"
            >
              Read my {journey.subtype} story &rarr;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
