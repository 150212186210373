import {
  Arrow,
  Content,
  Root,
  TooltipContentProps,
  Trigger,
} from "@radix-ui/react-tooltip";
import cn from "classnames";
import React, { FC, ReactNode } from "react";

interface Props extends TooltipContentProps {
  content?: ReactNode;
  children: ReactNode;
  disablePadding?: boolean;
  delayDuration?: number;
}

const Tooltip: FC<Partial<Props>> = ({
  content,
  children,
  disablePadding,
  delayDuration = 25,
  ...props
}) => {
  if (content === null || content === undefined || content === false) {
    return <>{children}</>;
  }

  return (
    <Root delayDuration={delayDuration}>
      <Trigger>{children}</Trigger>
      <Content {...props}>
        <div
          className={cn("bg-gray-900 text-white rounded-lg text-xs z-10", {
            "py-1 px-2": !disablePadding,
          })}
        >
          {content} <Arrow className="fill-current text-gray-900" />
        </div>
      </Content>
    </Root>
  );
};

export default Tooltip;
