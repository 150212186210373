import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions } from "react-query";
import slugify from "slugify";

import {
  CategorizedTopics,
  CategorizedTopicsDto,
  Topic,
  TopicExt,
} from "../types";

export const getCategorizedTopics = (
  accessToken?: string,
  signal?: AbortSignal
): Promise<CategorizedTopics> =>
  api
    .get<CategorizedTopicsDto>("/v4/community/topics/categorized", {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : null,
      signal,
    })
    .then(({ data }) => ({
      communityFilters: data.community_filters.map((topic) => ({
        about: topic.about,
        aboutUrl: topic.about_url,
        category: topic.category,
        createdAt: topic.created_at,
        description: topic.description,
        id: topic.id,
        imageUrl: topic.image_url,
        isFollowed: topic.is_followed,
        ordering: topic.ordering,
        title: topic.title,
        updatedAt: topic.updated_at,
      })),
      subtypes: data.subtypes.map((topic) => ({
        about: topic.about,
        aboutUrl: topic.about_url,
        category: topic.category,
        createdAt: topic.created_at,
        description: topic.description,
        id: topic.id,
        imageUrl: topic.image_url,
        isFollowed: topic.is_followed,
        ordering: topic.ordering,
        title: topic.title,
        updatedAt: topic.updated_at,
      })),
    }));

export const getKey = (accessToken?: string) => [
  "topics",
  accessToken ?? "no-access-token",
];

export type MappedTopics = Record<string, TopicExt>;

export const mapTopics = (topics: CategorizedTopics): MappedTopics => {
  const values = Object.values(topics) as Array<Topic[]>;

  return values
    .filter((item) => Array.isArray(item))
    .flat()
    .map((item) => ({
      ...item,
      slug: slugify(item.title, { lower: true, strict: true }),
    }))
    .reduce((acc: MappedTopics, curr: TopicExt) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
};

const useTopics = <T = CategorizedTopics>(
  options?: UseQueryOptions<CategorizedTopics, Error, T>
) => {
  const { data: session } = useSession();

  return useQuery(
    getKey(session?.accessToken),
    ({ signal }) => getCategorizedTopics(session?.accessToken, signal),
    {
      ...(options ?? {}),
    }
  );
};

export default useTopics;
