import IntakeCallSchedulerModal from "@core/scheduler-modals/IntakeCallSchedulerModal";
import Button from "@core/ui/Button";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import { TopicButtons } from "@features/community-v2/components/TopicSidebar";
import cn from "classnames";
import { isEmpty, throttle } from "lodash";
import Image from "next/image";
import { useRouter } from "next/router";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { DesktopLinkPositions, NavigationLink } from "./types";

const CTA_BUTTON_LABEL = "Book a free call";

interface GetNavigationLinksArgs {
  onChangeExpandedNavigationLinkId: Dispatch<SetStateAction<string>>;
  onClickLogIn: () => void;
}

const getNavigationLinks = ({
  onChangeExpandedNavigationLinkId,
  onClickLogIn,
}: GetNavigationLinksArgs): NavigationLink[] => {
  return [
    {
      id: "why-nocd",
      title: "Why NOCD?",
      desktopPosition: DesktopLinkPositions.LEFT,
      Link: ({ children, className }) => (
        <a
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/why-nocd`}
          className={cn(
            "block transition-colors hover:text-teal-600",
            className
          )}
        >
          {children}
        </a>
      ),
    },
    {
      id: "learn-more",
      title: "Learn about OCD",
      desktopPosition: DesktopLinkPositions.LEFT,
      Link: ({ className, children }) => (
        <a
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/learn/blog`}
          className={cn(
            "block transition-colors hover:text-teal-600",
            className
          )}
        >
          {/* <span className="text-14px font-light desktop:text-16px">
            Learn about OCD
          </span> */}
          {children}
        </a>
      ),
    },
    {
      id: "about-us",
      title: "About us",
      desktopPosition: DesktopLinkPositions.LEFT,
      Link: ({ children, className }) => (
        <button
          type="button"
          className={cn(
            "w-full text-left transition-colors hover:text-teal-600",
            className
          )}
          onClick={() =>
            onChangeExpandedNavigationLinkId((previousState) =>
              previousState === "about-us" ? "" : "about-us"
            )
          }
        >
          {children}
        </button>
      ),
      children: [
        {
          title: "Our therapists",
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/therapists`,
          icon: (
            <Image
              width={28}
              height={20}
              src="/images/navbar/user-group.svg"
              alt=""
              aria-hidden="true"
            />
          ),
          isExternal: true,
        },
        {
          title: "Reviews",
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/reviews`,
          icon: (
            <Image
              width={26}
              height={20}
              src="/images/navbar/conversation.svg"
              alt=""
              aria-hidden="true"
            />
          ),
          isExternal: true,
        },
        {
          title: "Our story",
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/about-us/our-story`,
          icon: (
            <Image
              width={29}
              height={25}
              src="/images/navbar/book-with-star.svg"
              alt=""
              aria-hidden="true"
            />
          ),
          isExternal: true,
        },
        {
          title: "Careers",
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/about-us/careers`,
          icon: (
            <Image
              width={24}
              height={21}
              src="/images/navbar/briefcase.svg"
              alt=""
              aria-hidden="true"
            />
          ),
          tag: "We're hiring!",
          isExternal: true,
        },
        {
          title: "Contact us",
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/about-us/contact-us`,
          icon: (
            <Image
              width={24}
              height={17}
              src="/images/navbar/envelope.svg"
              alt=""
              aria-hidden="true"
            />
          ),
          isExternal: true,
        },
      ],
    },
    {
      id: "for-providers",
      title: "For providers",
      desktopPosition: DesktopLinkPositions.LEFT,
      Link: ({ children, className }) => (
        <button
          type="button"
          className={cn(
            "w-full text-left transition-colors hover:text-teal-600",
            className
          )}
          onClick={() =>
            onChangeExpandedNavigationLinkId((previousState) =>
              previousState === "for-providers" ? "" : "for-providers"
            )
          }
        >
          {children}
        </button>
      ),
      children: [
        {
          title: "Refer a patient",
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/referral`,
          isExternal: true,
          icon: (
            <Image
              width={24}
              height={17}
              src="/images/navbar/icon-contact-us.svg"
              alt=""
              aria-hidden="true"
            />
          ),
        },
        {
          title: "Provider resources",
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/provider-resources`,
          isExternal: true,
          icon: (
            <Image
              width={25}
              height={24}
              src="/images/navbar/icon-provider-resources.svg"
              alt=""
              aria-hidden="true"
            />
          ),
        },
        {
          title: "Collaborate with us",
          isExternal: true,
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/collaborate-with-therapists`,
          icon: (
            <Image
              width={16}
              height={16}
              src="/images/navbar/icon-collaborate.svg"
              alt=""
              aria-hidden="true"
            />
          ),
        },
        {
          title: "Careers",
          isExternal: true,
          icon: (
            <Image
              width={24}
              height={21}
              src="/images/navbar/icon-careers.svg"
              alt=""
              aria-hidden="true"
            />
          ),
          url: `${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/about-us/careers`,
        },
      ],
    },
    {
      id: "community",
      title: "Community",
      desktopPosition: DesktopLinkPositions.LEFT,
      Link: ({ children, className }) => (
        <button
          type="button"
          className={cn(
            "w-full text-left transition-colors hover:text-teal-600",
            className
          )}
          onClick={() =>
            onChangeExpandedNavigationLinkId((previousState) =>
              previousState === "community" ? "" : "community"
            )
          }
        >
          {children}
        </button>
      ),
    },
    {
      id: "community-filters",
      title: "Community filters",
      desktopPosition: DesktopLinkPositions.NONE,
      Link: () => (
        <div className="py-2">
          <TopicButtons />
        </div>
      ),
    },
    {
      id: "log-in",
      title: "Log in",
      desktopPosition: DesktopLinkPositions.RIGHT,
      Link: ({ children, className }) => (
        <button
          type="button"
          className={cn(
            "block transition-colors hover:text-teal-600",
            className
          )}
          onClick={onClickLogIn}
        >
          {children}
        </button>
      ),
    },
  ];
};

const getNavShadowClasses = (
  hideShadowOnMobile: boolean,
  hideShadowOnDesktop: boolean,
  isWindowScrolled: boolean,
  isExpanded: boolean
) => {
  if (hideShadowOnMobile && hideShadowOnDesktop) {
    return "shadow-none";
  }

  if (hideShadowOnMobile && !hideShadowOnDesktop) {
    if (isWindowScrolled || isExpanded) {
      return " tablet:shadow-navbar-scrolled";
    }
  }

  if (!hideShadowOnMobile && hideShadowOnDesktop) {
    if (isWindowScrolled || isExpanded) {
      return "shadow-navbar-scrolled tablet:shadow-none";
    }
  }

  if (!hideShadowOnMobile && !hideShadowOnDesktop) {
    if (isWindowScrolled || isExpanded) {
      return "shadow-navbar-scrolled";
    }
  }

  return "shadow-none";
};

export default function UnauthenticatedNavBar({
  hideShadowOnMobile = false,
  hideShadowOnDesktop = false,
}: {
  hideShadowOnMobile?: boolean;
  hideShadowOnDesktop?: boolean;
}): JSX.Element {
  const { openModal } = useAuthenticationModal();
  const [isSchedulerModalOpen, setIsSchedulerModalOpen] =
    useState<boolean>(false);
  const [expandedNavigationLinkId, setExpandedNavigationLinkId] =
    useState<string>("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isWindowScrolled, setIsWindowScrolled] = useState<boolean>(false);
  const Router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsWindowScrolled(false);
      } else {
        setIsWindowScrolled(true);
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 500);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
    setExpandedNavigationLinkId("");
  }, []);

  useEffect(() => {
    Router.events.on("routeChangeComplete", closeMenu);

    return () => {
      Router.events.off("routeChangeComplete", closeMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigationLinks = useMemo(
    () =>
      getNavigationLinks({
        onChangeExpandedNavigationLinkId: setExpandedNavigationLinkId,
        onClickLogIn: () => {
          closeMenu();
          return openModal({
            redirectAfterAuthentication: true,
          });
        },
      }),
    [closeMenu, openModal]
  );

  const handleOpenSchedulerModal = useCallback(
    () => setIsSchedulerModalOpen(true),
    []
  );

  const handleCloseSchedulerModal = useCallback(
    () => setIsSchedulerModalOpen(false),
    []
  );

  const handleCloseSubMenu = useCallback(
    () => setExpandedNavigationLinkId(""),
    []
  );

  const handleToggleSubMenu = useCallback(
    () => setIsMobileMenuOpen((previousState) => !previousState),
    []
  );

  const handleChangeExpandedNavigationLinkId = useCallback(
    (newExpandedMenuId: string) =>
      setExpandedNavigationLinkId((previousState) =>
        previousState === newExpandedMenuId ? "" : newExpandedMenuId
      ),
    []
  );
  return (
    <div
      className={cn(
        "duration-600 fixed left-0 right-0 top-0 z-20 bg-white font-poppins transition-shadow",
        getNavShadowClasses(
          hideShadowOnMobile,
          hideShadowOnDesktop,
          isWindowScrolled,
          !isEmpty(expandedNavigationLinkId)
        )
      )}
    >
      <DesktopMenu
        expandedNavigationLinkId={expandedNavigationLinkId}
        isMobileMenuOpen={isMobileMenuOpen}
        navigationLinks={navigationLinks}
        closeSubMenu={handleCloseSubMenu}
        callToAction={
          <Button
            rounded="full"
            className="block !h-[36px] !min-h-[36px] !w-[162.64px] text-16px"
            disablePadding
            onClick={handleOpenSchedulerModal}
          >
            {CTA_BUTTON_LABEL}
          </Button>
        }
      />

      <MobileMenu
        callToAction={
          <button
            type="button"
            className="hidden text-[17px] text-teal-600 small-phone:block"
            onClick={handleOpenSchedulerModal}
          >
            {CTA_BUTTON_LABEL}
          </button>
        }
        ctaButtonLabel={CTA_BUTTON_LABEL}
        expandedNavigationLinkId={expandedNavigationLinkId}
        isOpen={isMobileMenuOpen}
        navigationLinks={navigationLinks}
        onChangeExpandedNavigationLinkId={handleChangeExpandedNavigationLinkId}
        onClickCTA={handleOpenSchedulerModal}
        onToggleMenu={handleToggleSubMenu}
      />

      <IntakeCallSchedulerModal
        key={`scheduler-${String(isSchedulerModalOpen)}`}
        isOpen={isSchedulerModalOpen}
        source="web_app_navbar_cta"
        handleClose={handleCloseSchedulerModal}
      />
    </div>
  );
}
