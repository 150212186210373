import { useSession } from "@core/hooks/useSession";
import Container from "@core/layout/Container";
import cn from "classnames";
import Image from "next/image";
import { forwardRef } from "react";

import FeedSettingsDialog from "./FeedSettingsDialog";
import Header from "./Header";
import TopicSidebar from "./TopicSidebar";

interface Props {
  children: React.ReactNode;
  hideSidebar?: boolean;
  hideHero?: boolean;
  classes?: {
    container?: string;
  };
}

const PageWrapper = forwardRef<HTMLDivElement, Props>(
  ({ children, hideSidebar, classes, hideHero }, ref) => {
    const { data: session } = useSession();
    const isAuthenticated = !!session?.accessToken;

    return (
      <div
        ref={ref}
        className="relative flex-1"
        style={{
          backgroundColor: "#F8FBFF",
        }}
      >
        <span
          style={{ height: "calc(100vh + 800px)" }}
          className="hidden tablet:block absolute top-0 w-full"
        >
          {isAuthenticated ? (
            <Image
              src="/images/community/background-gradients/desktop-auth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          ) : (
            <Image
              src="/images/community/background-gradients/desktop-unauth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          )}
        </span>

        <span
          style={{ height: "calc(100vh + 800px)" }}
          className="tablet:hidden absolute top-0 w-full"
        >
          {isAuthenticated ? (
            <Image
              src="/images/community/background-gradients/mobile-auth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          ) : (
            <Image
              src="/images/community/background-gradients/mobile-unauth.svg"
              layout="fill"
              objectFit="cover"
              objectPosition="left top"
              z-index={0}
            />
          )}
        </span>

        <div className="-mb-10 -mt-10">{hideHero ? null : <Header />}</div>

        <Container
          disablePadding
          className={cn(classes?.container, "pb-32 z-1 relative")}
        >
          {hideSidebar ? (
            children
          ) : (
            <div className="laptop:grid laptop:grid-cols-3 laptop:gap-x-6 pb-32">
              <div className="tablet:col-span-2">{children}</div>

              <div className="hidden laptop:block">
                <TopicSidebar />
              </div>
            </div>
          )}
        </Container>

        {isAuthenticated ? <FeedSettingsDialog /> : null}
      </div>
    );
  }
);

PageWrapper.displayName = "PageWrapper";

export default PageWrapper;
