import { FC } from "react";

const Hero: FC = () => {
  return (
    <div
      style={{
        backgroundImage: "url('/images/community/header-diamonds-overlay.svg')",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto 100%",
      }}
      className="relative font-poppins leading-tight"
    >
      <div className="text-center text-black py-20 tablet:py-30 px-6">
        <h1 className="font-bold text-24px tablet:text-28px mb-4">
          Welcome to <br /> the NOCD Community
        </h1>

        <p className="tablet:text-18px">
          Kickstart your recovery journey with a caring community of others{" "}
          <br className="hidden tablet:block" /> working to conquer OCD
        </p>
      </div>
    </div>
  );
};

export default Hero;
