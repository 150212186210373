/* eslint-disable @next/next/no-img-element */
import "@egjs/react-flicking/dist/flicking.css";

import IntakeCallSchedulerModal from "@core/scheduler-modals/IntakeCallSchedulerModal";
import { CarouselItemImage, PostExt } from "@features/community-v2/types";
import dynamic from "next/dynamic";
import Image, { StaticImageData } from "next/image";
import { useRouter } from "next/router";
import CommunitySpotlightIcon from "public/images/community/community-spotlight-icon.svg";
import {
  ForwardedRef,
  forwardRef,
  memo,
  MouseEvent,
  ReactElement,
  useCallback,
  useState,
} from "react";

import { newPostDialogStore } from "../ActionBar";
import { feedSettingsDialogStore } from "../FeedSettingsDialog";
import PostCard from "../PostCard";

const Flicking = dynamic(() => import("@egjs/react-flicking"));

interface ItemProps {
  children: React.ReactNode;
}

const Item = forwardRef(
  (
    { children }: ItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ): ReactElement => {
    return (
      <div className="mr-4 mb-4 cursor-pointer" ref={ref}>
        {children}
      </div>
    );
  }
);

Item.displayName = "Item";

const FlickingItem = memo(Item);

interface Props {
  items: PostExt["post_type_data"]["carousel_items"];
}

const PostCarousel = ({ items }: Props): JSX.Element => {
  const { onOpen: openNewPostDialog } = newPostDialogStore();
  const { push } = useRouter();
  const handleClick = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
  }, []);
  const { onOpen: openFeedSettingsDialog } = feedSettingsDialogStore();
  const [isSchedulerModalOpen, setIsSchedulerModalOpen] =
    useState<boolean>(false);

  return (
    <div onClick={handleClick} role="presentation">
      <div className="flex items-center gap-2 mb-2">
        <Image
          src={CommunitySpotlightIcon as StaticImageData}
          alt="Community Spotlight"
        />

        <p className="text-18px">Community spotlight</p>
      </div>

      <Flicking
        align="prev"
        horizontal
        resizeOnContentsReady
        bound
        preventClickOnDrag
      >
        {items.map((item) => (
          <FlickingItem key={item.post_id}>
            {item.type === "image" && (
              <div
                role="presentation"
                onClick={() => {
                  if (
                    (item.data as CarouselItemImage).deeplink_url.includes(
                      "/link/post/"
                    )
                  ) {
                    void push(`/community/posts/${item.post_id}`);
                  }
                  if (
                    (item.data as CarouselItemImage).deeplink_url.includes(
                      "/calendar"
                    )
                  ) {
                    void setIsSchedulerModalOpen(true);
                  }
                  if (
                    (item.data as CarouselItemImage).deeplink_url.includes(
                      "create_post"
                    )
                  ) {
                    void openNewPostDialog();
                  }
                  if (
                    (item.data as CarouselItemImage).deeplink_url.includes(
                      "community_filter_menu"
                    )
                  ) {
                    void openFeedSettingsDialog();
                  }
                }}
              >
                <img
                  src={(item.data as CarouselItemImage).image_url}
                  alt=""
                  className="max-h-[220px] tablet:max-h-[300px] rounded pointer-events-none"
                />
              </div>
            )}
            {item.type === "post" && (
              <div
                className="max-w-[280px] tablet:max-w-[400px] border-gray-200 rounded-xl border p-4"
                role="presentation"
                onClick={() =>
                  push(`/community/posts/${item.data.post.thread_id}`)
                }
              >
                <PostCard
                  post={item.data.post}
                  rootPostId={item.data.post.thread_id}
                  variant="feed"
                  key={item.post_id}
                />
              </div>
            )}
          </FlickingItem>
        ))}
      </Flicking>

      <IntakeCallSchedulerModal
        isOpen={isSchedulerModalOpen}
        handleClose={() => setIsSchedulerModalOpen(false)}
        source="post_carousel"
      />
    </div>
  );
};

export default PostCarousel;
