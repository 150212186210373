import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

import { useSortOptionsStore } from "../components/ActionBar";
import transformPaginatedPosts from "../transformers/transformPaginatedPosts";
import { ApiPaginatedPosts, PaginatedPosts } from "../types";

export const getPublicPostsQueryKey = (
  segmentId?: string,
  subtype?: string,
  sortBy?: "Most recent" | "Most popular"
): string[] => ["public-posts", segmentId, subtype, sortBy].filter(Boolean);

export const getPublicPosts = (
  segmentId?: string,
  subtype?: string,
  signal?: AbortSignal,
  sortBy?: "new" | "popular"
): Promise<PaginatedPosts> => {
  // build the query for the request
  // note :: if the segment id isn't passed, select the new feed
  const query = Object.entries({
    segment_id: segmentId,
    // filters: "my_topics",
    limit: 20,
    subtype,
    sort_by: sortBy,
  })
    .filter(([_, value]) => !!value)
    .map((item) => item.join("="))
    .join("&");

  return api
    .get<ApiPaginatedPosts>(`/v3/posts?${query}`, { signal })
    .then(({ data: page }) => transformPaginatedPosts(page));
};

export const usePublicPosts = (
  segmentId?: string,
  subtype?: string
): UseQueryResult<PaginatedPosts, AxiosError> => {
  const { sortBy } = useSortOptionsStore();
  return useQuery({
    queryKey: getPublicPostsQueryKey(segmentId, subtype, sortBy),
    queryFn: ({ signal }) =>
      getPublicPosts(
        segmentId,
        subtype,
        signal,
        sortBy === "Most recent" ? "new" : "popular"
      ),
  });
};
