import { useSession } from "@core/hooks/useSession";
import { recordUnauthCommunityRegistrationClick } from "@core/services/gtm";
import Loader from "@core/ui/Loader";
import { useAuthenticationModal } from "@features/auth";
import { Menu, Transition } from "@headlessui/react";
import DotsHorizontalIcon from "@heroicons/react/outline/DotsHorizontalIcon";
import cn from "classnames";
import Router from "next/router";
import {
  Fragment,
  MouseEvent,
  ReactElement,
  useCallback,
  useState,
} from "react";
import toast from "react-hot-toast";

import { useMutatePostAction } from "../hooks/useMutatePostAction";
import { usePostActions } from "../hooks/usePostActions";
import {
  Post,
  PostAction,
  PostActionIds,
  PostAvatarTypes,
  PostExt,
  PostId,
  RootPostId,
} from "../types";
import BookmarkButton from "./BookmarkButton";
import DeletePostDialog from "./DeletePostDialog";
import FlagPostDialog from "./FlagPostDialog";
import PostActionIcon from "./PostActionIcon";

interface PostActionsRenderProps {
  isLoading: boolean;
  data: PostAction[];
}

interface PostActionsProps {
  postId: PostId;
  rootPostId: RootPostId;
  children: (props: PostActionsRenderProps) => ReactElement;
}

const PostActions = ({ postId, children }: PostActionsProps) => {
  const data = usePostActions(postId);

  return children(data);
};

interface PostActionMenuProps {
  post: Post | PostExt;
  rootPostId: RootPostId;
  redirectAfterDeletion: boolean;
}

export default function PostActionMenu({
  post,
  rootPostId,
  redirectAfterDeletion,
}: PostActionMenuProps): JSX.Element {
  const { data: session } = useSession();
  const { user } = session || {};
  const { mutateAsync: performPostAction } = useMutatePostAction(rootPostId);

  const [isDeletePostDialogOpen, setIsDeletePostDialogOpen] = useState(false);
  const [isFlagPostDialogOpen, setIsFlagPostDialogOpen] = useState(false);

  const isOwnPost = +post.user_id === +user?.id;

  // This is kind of a hack. Right now, the API returns one  of three avatar
  // types: `nocd_pro`, `nocd_advocate`, and `legacy`. `nocd_pro` means the user
  // is a therapist. `nocd_advocate` means the user is an advocate. `legacy`
  // means the user is a regular community member. We don't want users to be
  // able to flag posts by therapists and advocates, so we limit this
  // functionality to posts by `legacy` users.
  const isNotTherapistOrAdvocatePost =
    post.avatar_type === PostAvatarTypes.DEFAULT;

  const handleClick = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
  }, []);

  const { openModal } = useAuthenticationModal();

  const handleUnauthenticatedClick = useCallback(() => {
    try {
      recordUnauthCommunityRegistrationClick();
    } catch (gtmError) {
      console.error(
        "Error recording registration in GTM ",
        gtmError instanceof Error ? gtmError.message : ""
      );
    }
    openModal({
      redirectAfterAuthentication: false,
      variant: "community",
    });
  }, [openModal]);

  const isAuthenticated = !!session?.accessToken;

  return (
    <span className="flex items-center">
      <BookmarkButton
        post={post}
        rootPostId={rootPostId}
        className="p-3 -mr-3 -mb-3"
      />
      <Menu as="div" className="relative">
        <div onClick={handleClick} role="presentation">
          <Menu.Button className="block p-3 -mr-3 -mb-3">
            <DotsHorizontalIcon className="w-6 text-gray-500 transition-colors hover:text-gray-700 focus:text-gray-700" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={cn(
              "absolute right-0 z-10 w-90 origin-top-right rounded-md bg-white py-4 px-3 shadow-lg border border-gray-200"
            )}
          >
            <PostActions rootPostId={rootPostId} postId={post.id}>
              {({ data, isLoading }) => (
                <>
                  {isLoading && (
                    <div className="flex justify-center my-10">
                      <Loader />
                    </div>
                  )}

                  {!isLoading
                    ? [
                        ...(data ?? []),
                        // If it's another user's post, allow the user to hide it
                        !isOwnPost
                          ? {
                              id: PostActionIds.HIDE_POST,
                              enabled: true,
                              toast_success_title: "Post hidden",
                              title: PostActionIds.HIDE_POST,
                              description:
                                "I don't want to see this in my feed",
                            }
                          : null,

                        // eslint-disable-next-line no-nested-ternary
                        !isOwnPost
                          ? post.isBookmarked
                            ? {
                                id: PostActionIds.UNBOOKMARK_POST,
                                enabled: true,
                                toast_success_title: "Post unbookmarked",
                                title: PostActionIds.UNBOOKMARK_POST,
                                description: "Remove this from my bookmarks",
                              }
                            : {
                                id: PostActionIds.BOOKMARK_POST,
                                enabled: true,
                                toast_success_title: "Post bookmarked",
                                title: PostActionIds.BOOKMARK_POST,
                                description: "Add this to my bookmarks",
                              }
                          : null,
                      ]
                        .filter(Boolean)
                        // Ensure only actions we support are shown to the user
                        .filter(({ id }) =>
                          Object.values(PostActionIds).includes(id)
                        )
                        .map((action) => (
                          <Menu.Item key={action.id}>
                            {({ active }) => (
                              <button
                                type="button"
                                className={cn(
                                  "flex w-full items-center space-x-4 rounded-lg px-3 py-2 text-left",
                                  active && "bg-gray-100"
                                )}
                                onClick={(event) => {
                                  event.stopPropagation();

                                  if (!isAuthenticated) {
                                    return handleUnauthenticatedClick();
                                  }

                                  return (
                                    performPostAction({
                                      actionId: action.id,
                                      postId: post.id,
                                      isEnabled: !action.enabled,
                                      userId: post.user_id,
                                    })
                                      // Redirect the user to the homepage if the action
                                      // hides the current post thread
                                      .then(() => {
                                        if (
                                          post.id === rootPostId &&
                                          [
                                            PostActionIds.HIDE_POST,
                                            PostActionIds.FLAG_POST,
                                            PostActionIds.DELETE_POST,
                                            PostActionIds.REASSURANCE_SEEKING,
                                          ].includes(action.id) &&
                                          Router.route !== "/community/posts"
                                        ) {
                                          void Router.replace(
                                            "/community/posts"
                                          );
                                        }
                                      })
                                      .then(() =>
                                        toast.success(
                                          action.toast_success_title,
                                          {
                                            icon: (
                                              <PostActionIcon
                                                className="text-teal-600"
                                                actionId={action.id}
                                              />
                                            ),
                                          }
                                        )
                                      )
                                      .catch((error: Error) =>
                                        toast.error(error.message)
                                      )
                                  );
                                }}
                              >
                                <div className="flex-shrink-0">
                                  <PostActionIcon
                                    className="text-gray-800"
                                    actionId={action.id}
                                    isEnabled={!action.enabled}
                                  />
                                </div>

                                <div className="text-left">
                                  <h2 className="font-bold text-16px">
                                    {action.title}
                                  </h2>

                                  <p className={cn("text-14px text-gray-600")}>
                                    {action.description}
                                  </p>
                                </div>
                              </button>
                            )}
                          </Menu.Item>
                        ))
                    : null}

                  {!isLoading && !isOwnPost && isNotTherapistOrAdvocatePost && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={cn(
                            "flex w-full items-center space-x-4 rounded-lg px-3 py-2 text-left",
                            active && "bg-gray-100"
                          )}
                          onClick={(event) => {
                            event.stopPropagation();

                            if (!isAuthenticated) {
                              return handleUnauthenticatedClick();
                            }

                            return toast.promise(
                              performPostAction({
                                actionId: PostActionIds.FLAG_POST,
                                postId: post.id,
                                isEnabled: null,
                                userId: post.user_id,
                                category: "reassurance",
                              }),
                              {
                                loading:
                                  "Marking post as reassurance-seeking...",
                                success:
                                  "This post has been marked as reassurance-seeking",
                                error: (err: Error) => err.message,
                              }
                            );
                          }}
                        >
                          <div className="flex-shrink-0">
                            <PostActionIcon
                              className="text-gray-800"
                              actionId={PostActionIds.REASSURANCE_SEEKING}
                            />
                          </div>

                          <div className="text-left">
                            <h2 className="font-bold text-16px">
                              {PostActionIds.REASSURANCE_SEEKING}
                            </h2>

                            <p className="text-gray-600 text-14px">
                              This is potential compulsive behavior
                            </p>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  )}

                  {!isLoading && isOwnPost && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={cn(
                            "flex w-full items-center space-x-4 px-4 py-4 text-left",
                            active && "bg-gray-100"
                          )}
                          onClick={(event) => {
                            event.stopPropagation();
                            setIsDeletePostDialogOpen(true);
                          }}
                        >
                          <div className="flex-shrink-0">
                            <PostActionIcon
                              className="text-gray-800"
                              actionId={PostActionIds.DELETE_POST}
                            />
                          </div>

                          <div className="text-left">
                            <h2 className="font-bold text-16px">
                              {PostActionIds.DELETE_POST}
                            </h2>

                            <p className="text-gray-600 text-16px">
                              Remove this post permanently
                            </p>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  )}

                  {!isLoading && !isOwnPost && isNotTherapistOrAdvocatePost && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={cn(
                            "flex w-full items-center space-x-4 rounded-lg px-3 py-2 text-left",
                            active && "bg-gray-100"
                          )}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (!isAuthenticated) {
                              handleUnauthenticatedClick();
                            } else {
                              setIsFlagPostDialogOpen(true);
                            }
                          }}
                        >
                          <div className="flex-shrink-0">
                            <PostActionIcon
                              className="text-gray-800"
                              actionId={PostActionIds.FLAG_POST}
                            />
                          </div>

                          <div className="text-left">
                            <h2 className="font-bold text-16px">
                              {PostActionIds.FLAG_POST}
                            </h2>

                            <p className="text-gray-600 text-14px">
                              This violates community guidelines
                            </p>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  )}

                  {!isLoading && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={cn(
                            "flex w-full items-center space-x-4 rounded-lg px-3 py-2 text-left",
                            active && "bg-gray-100"
                          )}
                          onClick={(event) => {
                            event.stopPropagation();
                            navigator?.clipboard
                              ?.writeText(
                                `${process.env.NEXT_PUBLIC_WEB_APP_BASE_URL}/community/posts/${rootPostId}`
                              )
                              .then(() =>
                                toast.success("Link copied to clipboard")
                              )
                              .catch(() => toast.error("Something went wrong"));
                          }}
                        >
                          <div className="flex-shrink-0">
                            <PostActionIcon
                              className="text-gray-800"
                              actionId={PostActionIds.COPY_LINK}
                            />
                          </div>

                          <div className="text-left">
                            <h2 className="font-bold text-16px">
                              {PostActionIds.COPY_LINK}
                            </h2>

                            <p className="text-gray-600 text-14px">
                              Share this post with others
                            </p>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </>
              )}
            </PostActions>
          </Menu.Items>
        </Transition>
      </Menu>

      <DeletePostDialog
        rootPostId={rootPostId}
        post={post}
        isOpen={isDeletePostDialogOpen}
        onClose={() => setIsDeletePostDialogOpen(false)}
        redirectAfterDeletion={redirectAfterDeletion}
      />

      <FlagPostDialog
        rootPostId={rootPostId}
        post={post}
        isOpen={isFlagPostDialogOpen}
        onClose={() => setIsFlagPostDialogOpen(false)}
      />
    </span>
  );
}
