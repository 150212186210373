import { useSession } from "@core/hooks/useSession";
import Loader from "@core/ui/Loader";
import { Transition } from "@headlessui/react";
import { BookmarkIcon, ChevronUpIcon, HomeIcon } from "@heroicons/react/solid";
import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useCallback, useState } from "react";
import { isBrowser } from "react-device-detect";
import Sticky from "react-stickynode";
import slugify from "slugify";

import useTopics from "../hooks/useTopics";
import { SidebarCta } from "./RecentPostsSidebar";

export const TopicButtons = ({
  openByDefault = false,
}: {
  openByDefault?: boolean;
}) => {
  const { data } = useTopics();
  const { asPath } = useRouter();
  const { data: session } = useSession();

  const isAuthenticated = !!session?.accessToken;

  const [openAccordion, setOpenAccordion] = useState<
    "subtypes" | "communityFilters"
  >(openByDefault ? "subtypes" : null);

  const handleAccordionClick = useCallback(
    (accordion: "subtypes" | "communityFilters") => {
      if (openAccordion === accordion) {
        setOpenAccordion(null);
        return;
      }
      setOpenAccordion(accordion);
    },
    [openAccordion]
  );

  return (
    <>
      <Link href="/community/posts" passHref>
        <a
          className={cn(
            "flex gap-1 items-center hover:text-teal-600 py-2",
            asPath === "/community/posts" && "text-teal-600 font-bold"
          )}
        >
          <span>
            <HomeIcon className="tablet:w-5 tablet:h-5 w-4 h-4" />
          </span>

          <span>All posts</span>
        </a>
      </Link>

      <h2 className="font-medium p-1 mt-2 hidden tablet:block">
        COMMUNITY TOPICS
      </h2>

      <hr className="my-1 bg-gray-200 h-[1px] hidden tablet:block" />

      <button
        className="flex justify-between w-full items-center px-1 py-2 hover:tablet:bg-teal-100 rounded-lg"
        type="button"
        onClick={() => handleAccordionClick("subtypes")}
      >
        <span>OCD Subtypes</span>
        <ChevronUpIcon
          className={cn(
            "w-5 h-5 transform duration-300",
            openAccordion === "subtypes" && "rotate-180 text-teal-600"
          )}
        />
      </button>

      <Transition show={openAccordion === "subtypes"}>
        <ul
          className={cn(
            "border-l border-gray-200 grid gap-2 tablet:gap-[6px] m-1",
            "transition duration-300 ease-in",
            openAccordion !== "subtypes" && "opacity-0"
          )}
        >
          {data?.subtypes
            ?.map((item) => ({
              ...item,
              slug: slugify(item.title, { lower: true, strict: true }),
            }))
            ?.map((subtype) => {
              const isActive = asPath.includes(subtype.slug);

              return (
                <li key={subtype.id} className="text-[0px]">
                  <Link
                    href={
                      isActive
                        ? "/community/posts"
                        : `/community/topics/${subtype.slug}`
                    }
                    passHref
                  >
                    <a
                      className={cn(
                        "inline-block px-4 hover:text-teal-600 text-14px",
                        isActive && "font-bold text-teal-600"
                      )}
                    >
                      {subtype.title}
                    </a>
                  </Link>
                </li>
              );
            })}
        </ul>
      </Transition>

      <button
        className="flex justify-between w-full items-center px-1 py-2 hover:tablet:bg-teal-100 rounded-lg"
        type="button"
        onClick={() => handleAccordionClick("communityFilters")}
      >
        <span>Community filters</span>
        <ChevronUpIcon
          className={cn(
            "w-5 h-5 transform duration-300",
            openAccordion === "communityFilters" && "rotate-180 text-teal-600"
          )}
        />
      </button>

      <Transition show={openAccordion === "communityFilters"}>
        <ul
          className={cn(
            "border-l border-gray-200 grid gap-2 tablet:gap-[6px] m-1",
            "transition duration-300 ease-in",
            openAccordion !== "communityFilters" && "opacity-0"
          )}
        >
          {data?.communityFilters
            ?.map((item) => ({
              ...item,
              slug: slugify(item.title, { lower: true, strict: true }),
            }))
            ?.map((subtype) => {
              const isActive = asPath.includes(subtype.slug);

              return (
                <li key={subtype.id} className="text-[0px]">
                  <Link
                    href={
                      isActive
                        ? "/community/posts"
                        : `/community/topics/${subtype.slug}`
                    }
                    passHref
                  >
                    <a
                      className={cn(
                        "inline-block px-4 hover:text-teal-600 text-14px",
                        isActive && "font-bold text-teal-600"
                      )}
                    >
                      {subtype.title}
                    </a>
                  </Link>
                </li>
              );
            })}
        </ul>
      </Transition>

      {isAuthenticated ? (
        <Link href="/community/favorites" passHref>
          <a
            className={cn(
              "flex mt-1 py-2 tablet:mt-2 gap-[2px] items-center hover:text-teal-600",
              asPath.includes("favorites") && "text-teal-600 font-bold"
            )}
          >
            <BookmarkIcon className="w-4 h-4 tablet:text-indigo-600" />

            <span>Saved posts</span>
          </a>
        </Link>
      ) : null}
    </>
  );
};

const TopicSidebar: FC = () => {
  const { isLoading } = useTopics();

  if (isLoading) {
    return (
      <div className="flex text-32px text-indigo-600 justify-center items-center py-12">
        <Loader />
      </div>
    );
  }

  return (
    <Sticky top={100} enabled={isBrowser}>
      <div className="bg-white rounded-xl px-4 py-4 font-poppins">
        <TopicButtons openByDefault />

        <p className="text-14px mt-4 px-1">
          If you are in crisis, please use these{" "}
          <a
            target="_blank"
            className="text-indigo-600 font-medium underline"
            href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/emergency-resources`}
            rel="noreferrer"
          >
            emergency resources
          </a>{" "}
          to find immediate help.
        </p>
      </div>

      <SidebarCta />
    </Sticky>
  );
};

export default React.memo(TopicSidebar);
